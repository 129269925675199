.contact{
    position: relative;
    .section-body{
        position: relative;
        form{
            position: relative;
            > div{
                > input {
                    margin: 3rem 0;
                    &.form-control{
                        padding: 0;
                        border-radius: 0;
                        border-top: 0;
                        border-left: 0;
                        border-right: 0;
                        border-bottom: 1px solid var(--clater);
                        &:focus{
                            outline: none;
                            box-shadow: none;
                        }
                        &::placeholder {
                            text-align: left;
                            opacity: .75;
                        }
                    }
                }
                textarea{
                    &.form-control{
                        padding: 0;
                        border-radius: 0;
                        border-top: 0;
                        border-left: 0;
                        border-right: 0;
                        border-bottom: 1px solid var(--clater);
                        &:focus{
                            outline: none;
                            box-shadow: none;
                        }
                        &::placeholder {
                            text-align: left;
                            vertical-align: bottom;
                            opacity: .75;
                        }
                    }
                }
            }
        }
        .card{
            position: relative;
            width: 100%;
            height: 100%;
            display: block;
            border: 1px solid #861831;
            border-radius: 20px;
            text-align: center;
            padding: 1rem;
            &:hover{
                background-color: #861831;
                color: #FFFFFF;
                .box-img{
                    img{
                        &.black{
                            opacity: 0;
                        }
                        &.white{
                            opacity: 1;
                        }
                    }
                }
            }
            .box-img{
                position: relative;
                width: 100%;
                height: 44px;
                display: flex;
                justify-content: center;
                img{
                    position: absolute;
                    width: auto;
                    height: 44px;
                    &.black{
                        opacity: 1;
                    }
                    &.white{
                        opacity: 0;
                    }
                }
            }
        }
    }
}